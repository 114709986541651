import Images from "~data/imageImports";
const ProcessData = [
      {
        id:"ps1",
        icon: "1",
        title: "Advice for Mining",
        text:
        "You can also record any previous experience at other jobs",
      },
      {
        id:"ps2",
        icon: "2",
        title: "Amazing Partners",
        text:
        "You can also record any previous experience at other jobs",
      },
      {
        id:"ps3",
        icon: "3",
        title: "Finalize & Publish",
        text:
          "You can also record any previous experience at other jobs",
      },
  ]
  export default ProcessData;